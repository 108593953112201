import React from 'react';
import LogRocket from 'logrocket';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Slider } from '@material-ui/core';

import ItemCard from './ItemCard';
import data from './store';

LogRocket.init('9aapdz/the-best-bang');

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    display: 'block',
    margin: '16px 0px 32px 0px',
    width: '100%',
    justifyContent: 'center',
    textAlign: 'center'
  },
  cardsLayout: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gridGap: '30px',
    maxWidth: '900px',
    margin: '0 auto 30px'
  },
  cardsLayoutItem: {
    position: 'relative'
  }
}));
let sortedData = data.sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt));
sortedData = sortedData.slice(0, 400).filter((obj) => obj.visible !== false); // keep top 200 items

export default function App() {
  const classes = useStyles();
  const [data, setData] = React.useState(sortedData);
  const [search, setSearch] = React.useState('');
  const [maxPrice, setMaxPrice] = React.useState(2000);
  const [type, setType] = React.useState('');

  const handleChange = (ev: any, newValue: any) => {
    setMaxPrice(newValue);
    // console.log('newValue', newValue);
  };
  return (
    <main>
      <section className={classes.pageTitle}>
        <h1 style={{ fontSize: '1em' }}>The Best Bang 💥</h1>
      </section>
      <div style={{ width: '40%', margin: '0 auto' }}>
        <input
          onChange={(ev) => setSearch(ev.target.value.trim().toLowerCase())}
          placeholder="Search a deal or browse..."
          style={{ padding: '5px', width: '100%' }}
        />
      </div>
      <div style={{ width: '40%', margin: '0 auto', marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <div>
            <input
              id="typeAll"
              type="radio"
              name="dealType"
              onChange={() => {
                setType('');
                setData(sortedData);
              }}
              checked={type === ''}
            />
            <label htmlFor="typeAll">
              <small>All deals</small>
            </label>
          </div>
          <div>
            <input
              id="typeTech"
              type="radio"
              name="dealType"
              onChange={() => {
                setType('tech');
                const filtered = sortedData.filter((obj) => obj.category === 'tech');
                setData(filtered);
              }}
              checked={type === 'tech'}
            />
            <label htmlFor="typeTech">
              <small>Tech</small>
            </label>
          </div>
        </div>
        <div style={{ width: '70%' }}>
          <small>$ Price Filter</small>
          <Slider
            defaultValue={3000}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={20}
            marks
            min={0}
            max={2000}
            onChange={handleChange}
          />
        </div>
      </div>

      <section className={classes.cardsLayout}>
        {data.map((item, index) => {
          if (search && item.title.toLowerCase().indexOf(search) < 0) {
            return null;
          }
          if (!item.title) {
            return null;
          }
          const price = parseFloat((item.price ?? '0').replace(/\$/g, '').replace(/,/g, ''));
          if (price > maxPrice) {
            return null;
          }
          return <ItemCard key={index} data={item} />;
        })}
      </section>

      <footer className="pageFooter">
        <a href="https://bit.ly/submitadeal">Add a new Deal</a>
      </footer>
    </main>
  );
}
